//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    async tableLoader({
      query
    }) {
      //console.log('ABCTable.71: this.reportSettings=', this.reportSettings);
      //console.log('ABCTable.72: query=', query);

      return await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbABC", "connections/getOzABC", {}, {});
    }
  },
  computed: {
    columns() {
      // const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2)
      return this.$store.state.connections.marketplace.title === "Wildberries" ? [{
        title: "SKU",
        show: true,
        name: "nmid",
        type: "text",
        width: 110,
        filter: "text",
        noOverflow: true
      }, {
        title: "产品识别代码",
        show: true,
        name: "supplierArticle",
        type: "text",
        width: 130,
        filter: "text",
        noOverflow: true
      }, {
        title: "品牌",
        show: true,
        name: "brand",
        type: "text",
        filter: "text",
        width: 160
      }, {
        title: "商店名称",
        show: true,
        name: "subject",
        type: "text",
        filter: "text",
        width: 220,
        getImage: item => item.image
      }, {
        title: `尺寸`,
        show: true,
        name: "techSize",
        type: "text",
        filter: "text",
        width: 100
      }, {
        title: `类别`,
        show: true,
        name: "category",
        type: "text",
        filter: "text",
        width: 120
      }, {
        title: `条码`,
        show: true,
        name: "Barcode",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: `团体(价格)`,
        show: true,
        name: "price_group_id",
        type: "slot",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: `组别(数量)`,
        show: true,
        name: "quantity_group_id",
        type: "slot",
        width: 180,
        filter: "text",
        noOverflow: true
      }, {
        title: `团体(一般)`,
        show: true,
        name: "aggregate_group_id",
        type: "slot",
        width: 160,
        filter: "text",
        noOverflow: true
      }, {
        title: `销售数量`,
        show: true,
        name: "quantity_sum",
        type: "number",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `销售金额`,
        show: true,
        name: "total_price_sum",
        type: "money",
        width: 180,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `结束于(天)`,
        show: true,
        name: "days_remains",
        type: "number",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `当前余额`,
        show: true,
        name: "stock_quantity",
        type: "number",
        width: 180,
        filter: "numberRange",
        noOverflow: true
      }]

      // OZON
      : [{
        title: "SKU",
        show: true,
        name: "sku",
        type: "text",
        width: 110,
        filter: "text",
        noOverflow: true
      }, {
        title: "产品识别代码",
        show: true,
        name: "offer_id",
        type: "text",
        width: 130,
        filter: "text",
        noOverflow: true
      }, {
        title: "商店名称",
        show: true,
        name: "name",
        type: "text",
        filter: "text",
        width: 500,
        getImage: item => item.image
      }, {
        title: `类别`,
        show: true,
        name: "category_name",
        type: "text",
        filter: "text",
        width: 220
      }, {
        title: `条码`,
        show: true,
        name: "barcode",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: `团体(价格)`,
        show: true,
        name: "price_group_id",
        type: "slot",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: `组别(数量)`,
        show: true,
        name: "quantity_group_id",
        type: "slot",
        width: 180,
        filter: "text",
        noOverflow: true
      }, {
        title: `团体(一般)`,
        show: true,
        name: "aggregate_group_id",
        type: "slot",
        width: 160,
        filter: "text",
        noOverflow: true
      }, {
        title: `销售数量`,
        show: true,
        name: "quantity_sum",
        type: "number",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `销售金额`,
        show: true,
        name: "total_price_sum",
        type: "money",
        width: 180,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `结束于(天)`,
        show: true,
        name: "days_remains",
        type: "number",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `当前余额`,
        show: true,
        name: "stock_quantity",
        type: "number",
        width: 180,
        filter: "numberRange",
        noOverflow: true
      }];
    }
  }
};